<template>
  <v-container fluid style="background-color: #f4f4f5 !important">
    <v-row class="mt-5" justify="center" align="center">
      <v-card width="820" elevation="2">
        <v-card-title class="text-small">
          <small><strong>Add product</strong></small>
        </v-card-title>
        <v-card-subtitle>
          General product information lives here
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field label="Name *" v-model="f.name" outlined dense>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                label="Type *"
                prepend-inner-icon="mdi-tooltip-text-outline"
                :items="get_supplier_product_type"
                item-value="id"
                item-text="name"
                outlined
                dense
                chips
                return-object
                :change="typechange(f.supplier_product_type_id)"
                v-model="f.supplier_product_type_id"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-if="showcode == 1"
                label="Code *"
                v-model="f.code"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <label>Description</label>
              <vue-editor v-model="f.description"></vue-editor>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#588BAD"
            dark
            class="pl-10 pr-10"
            small
            rounded
            style="texttransform: none !important; color: white"
            @click="save_supplier_product()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";

export default {
  components: { VueEditor },
  props: [],
  data: () => ({
    f: {
      supplier_product_type_id: null,
      name: null,
      code: null,
      description: null,
    },
    showcode: 0,
  }),
  mounted() {
    this.$store.dispatch("supplier/fetch_supplier_product_type");
  },
  created() {},
  computed: {
    ...mapGetters({
      get_supplier_setup: "supplier/get_supplier_setup",
      get_supplier_product_type: "supplier/get_supplier_product_type",
    }),
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit(
        "auth/setMessage",
        { show: true, message: message },
        { root: 1 }
      );
    },
    typechange(e) {
      this.showcode = e == null ? 0 : e.with_code;
      // this.f.supplier_product_type_id = e == null ? null : e.id;
    },
    async save_supplier_product() {
      await this.$axios
        .post("suppliers/products/add_products", {
          name: this.f.name,
          code: this.f.code,
          supplier_product_type_id: this.f.supplier_product_type_id.id,
          description: this.f.description,
          supplier_id: this.get_supplier_setup.id,
        })
        .then(({ data }) => {
          if (data.response) {
            this.showSnackBar(data.message);
            this.f = {};
            this.$store.dispatch("supplier/set_supplier_product", data.data);
            this.$router.push({ name: "/supplier-setup" });
          } else {
            this.showSnackBar(data.message);
          }
        });
    },
  },
  watch: {},
};
</script>
<style scoped lang="scss"></style>
