<template>
  <v-container
    fluid
    style="background-color: #f4f4f5 !important; height: 100%"
    class="pa-0 ma-0"
  >
    <div class="header">
      <strong class="white--text">
        {{ get_supplier_setup.supplier_name }} > New Product
      </strong>
    </div>
    <v-col cols="12" class="ma-0 pa-0">
      <v-tabs centered dark background-color="#588BAD" v-model="tab">
        <v-tab href="#tab-1" class="tabs"> Setup </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <Create />
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Create from "./product/CreateProduct.vue";

export default {
  components: { Create },
  props: [],
  data: () => ({
    tab: null,
  }),
  mounted() {},
  created() {},
  computed: {
    ...mapGetters({
      get_supplier_setup: "supplier/get_supplier_setup",
    }),
  },
  methods: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.header {
  max-width: 100%;
  background-color: #79a2bd;
  height: 50px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
  text-align: center;
  position: relative;
  z-index: 1;
  line-height: 50px;
}
.tabs {
  text-transform: none !important;
}
</style>
